const Ziggy = {
  url: 'https://muniolms.com',
  port: null,
  defaults: { domain: 'muniolms.com', accessdomain: 'munioaccess.com', account: 'ecoonline', language: 'en' },
  routes: {
    'dev.root': { uri: '/', methods: ['GET', 'HEAD'], domain: 'developer.{domain}', parameters: ['domain'] },
    'api.root': { uri: '/', methods: ['GET', 'HEAD'], domain: 'api.{domain}', parameters: ['domain'] },
    'api.web.countries': {
      uri: 'api/countries',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.languages': {
      uri: 'api/languages',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.exists': {
      uri: 'api/user/exists',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.account.exists': {
      uri: 'api/account/exists',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.companies': {
      uri: 'api/companies',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.companies.create': {
      uri: 'api/companies',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.auth.check.username': {
      uri: 'api/auth/check/username/{username}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'username'],
    },
    'api.web.auth.check.invite': {
      uri: 'api/auth/check/invite/{invite}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'invite'],
    },
    'api.web.auth.check.email': {
      uri: 'api/auth/check/email/{email}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'email'],
    },
    'api.web.auth.login': {
      uri: 'api/auth/login',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.auth.register': {
      uri: 'api/auth/register',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
      bindings: { account: 'subdomain' },
    },
    'api.web.auth.recover': {
      uri: 'api/auth/recover/{username}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'username'],
    },
    'api.web.auth.reset': {
      uri: 'api/auth/reset/{token}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'token'],
    },
    'api.web.auth.validate.profile': {
      uri: 'api/auth/validate/profile',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.auth.forget': {
      uri: 'api/auth/forget',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.auth.invite.accept': {
      uri: 'api/auth/invites/{invite}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'invite'],
      bindings: { invite: 'uuid' },
    },
    'api.web.auth.invite.decline': {
      uri: 'api/auth/invites/{invite}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'invite'],
      bindings: { invite: 'uuid' },
    },
    'api.web.shop.admin.products': {
      uri: 'api/shop/{shop}/admin/products',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.admin.product.update': {
      uri: 'api/shop/{shop}/admin/products/{product}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop', 'product'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.region.change': {
      uri: 'api/shop/{shop}/region',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
    },
    'api.web.shop.salespeople': {
      uri: 'api/shop/{shop}/salespeople',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.customer': {
      uri: 'api/shop/{shop}/customer/{company}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop', 'company'],
      bindings: { shop: 'slug', company: 'id' },
    },
    'api.web.shop.products': {
      uri: 'api/shop/{shop}/product',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.product': {
      uri: 'api/shop/{shop}/product/{product}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop', 'product'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.product.subscribe': {
      uri: 'api/shop/{shop}/product/{product}/subscribe',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop', 'product'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.product.unsubscribe': {
      uri: 'api/shop/{shop}/product/{product}/unsubscribe',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop', 'product'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.cart': {
      uri: 'api/shop/{shop}/cart',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.cart.clear': {
      uri: 'api/shop/{shop}/cart',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.cart.add': {
      uri: 'api/shop/{shop}/cart/{product}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop', 'product'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.cart.set': {
      uri: 'api/shop/{shop}/cart/{product}/{session?}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop', 'product', 'session'],
      bindings: { shop: 'slug' },
    },
    'api.web.shop.checkout': {
      uri: 'api/shop/{shop}/checkout',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
    },
    'api.web.shop.checkout.update.company': {
      uri: 'api/shop/{shop}/checkout/company',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
    },
    'api.web.shop.checkout.update.enrollment': {
      uri: 'api/shop/{shop}/checkout/enrollment',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
    },
    'api.web.shop.checkout.update.payment': {
      uri: 'api/shop/{shop}/checkout/payment',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
    },
    'api.web.shop.checkout.confirm': {
      uri: 'api/shop/{shop}/checkout/confirm',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
    },
    'api.web.shop.checkout.search': {
      uri: 'api/shop/{shop}/checkout/search',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'shop'],
    },
    'api.web.audits': {
      uri: 'api/audit',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.audits.context': {
      uri: 'api/audit/{type}/{id}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'type', 'id'],
    },
    'api.web.mobile': {
      uri: 'api/mobile',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.mobile.submit': {
      uri: 'api/mobile/submit',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.mobile.verify': {
      uri: 'api/mobile/verify',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.companies.context': {
      uri: 'api/context/{company}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
      bindings: { company: 'id' },
    },
    'api.web.integration': {
      uri: 'api/integrations/{integration}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'integration'],
      bindings: { integration: 'id' },
    },
    'api.web.integration.mode': {
      uri: 'api/integrations/{integration}/mode/{mode}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'integration', 'mode'],
      bindings: { integration: 'id' },
    },
    'api.web.integration.actions': {
      uri: 'api/integrations/{integration}/actions',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'integration'],
      bindings: { integration: 'id' },
    },
    'api.web.integration.actions.trigger': {
      uri: 'api/integrations/{integration}/actions',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'integration'],
      bindings: { integration: 'id' },
    },
    'api.web.integration.action': {
      uri: 'api/integrations/{integration}/actions/{action}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'integration', 'action'],
      bindings: { integration: 'id', action: 'ulid' },
    },
    'api.web.integration.action.events': {
      uri: 'api/integrations/{integration}/actions/{action}/events',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'integration', 'action'],
      bindings: { integration: 'id', action: 'ulid' },
    },
    'api.web.integration.action.retry': {
      uri: 'api/integrations/{integration}/actions/{action}/retry',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'integration', 'action'],
      bindings: { integration: 'id', action: 'ulid' },
    },
    'api.web.integration.action.cancel': {
      uri: 'api/integrations/{integration}/actions/{action}/cancel',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'integration', 'action'],
      bindings: { integration: 'id', action: 'ulid' },
    },
    'api.web.user.counters': {
      uri: 'api/user/counters',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.employ': {
      uri: 'api/user/employ',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.access': {
      uri: 'api/user/access',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.access.browse': {
      uri: 'api/user/access/browse',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.access.browse.zones': {
      uri: 'api/user/access/browse/{company}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
      bindings: { company: 'id' },
    },
    'api.web.user.access.enrollment': {
      uri: 'api/user/access/enrollment/{company}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
    },
    'api.web.user.access.company': {
      uri: 'api/user/access/company/{company}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
      bindings: { company: 'id' },
    },
    'api.web.user.access.register': {
      uri: 'api/user/access/company/{company}/{zone}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company', 'zone'],
    },
    'api.web.user.access.unregister': {
      uri: 'api/user/access/company/{company}/{zone}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company', 'zone'],
    },
    'api.web.user.access.checkout': {
      uri: 'api/user/access/checkout/{requirement}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'requirement'],
      bindings: { requirement: 'id' },
    },
    'api.web.user.course': {
      uri: 'api/user/courses/{course}/{context?}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course', 'context'],
    },
    'api.web.user.course.launch': {
      uri: 'api/user/courses/{course}/{context?}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course', 'context'],
    },
    'api.web.user.course.approve': {
      uri: 'api/user/courses/{course}/approve',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
    },
    'api.web.user.course.document': {
      uri: 'api/user/courses/{course}/document',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
    },
    'api.web.user.course.restart': {
      uri: 'api/user/courses/{course}/restart',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
    },
    'api.web.user.course.setdate': {
      uri: 'api/user/courses/{course}/setdate',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
    },
    'api.web.user.course.enroll': {
      uri: 'api/user/courses/{course}/enroll',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
    },
    'api.web.user.course.unenroll': {
      uri: 'api/user/courses/{course}/unenroll',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
    },
    'api.web.user.profile': {
      uri: 'api/user/profile',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.profile.password': {
      uri: 'api/user/profile/password',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.profile.validate': {
      uri: 'api/user/profile',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.profile.update': {
      uri: 'api/user/profile',
      methods: ['PATCH'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.certificate': {
      uri: 'api/user/certificate',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.certificate.add': {
      uri: 'api/user/certificate',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.certificate.delete': {
      uri: 'api/user/certificate/{id}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id'],
    },
    'api.web.user.certificate.addable': {
      uri: 'api/user/certificate/addable',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.user.session.enroll': {
      uri: 'api/user/sessions/{session}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'session'],
      bindings: { session: 'id' },
    },
    'api.web.user.session.unenroll': {
      uri: 'api/user/sessions/{session}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'session'],
      bindings: { session: 'id' },
    },
    'lms.user.gdpr': {
      uri: 'api/user/gdpr',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'lms.user.gdpr.request': {
      uri: 'api/user/gdpr/request',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'lms.user.gdpr.download': {
      uri: 'api/user/gdpr/download',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.access.gate': {
      uri: 'api/access/gates/{gate}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.update': {
      uri: 'api/access/gates/{gate}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.zones': {
      uri: 'api/access/gates/{gate}/zones',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.entrypoints': {
      uri: 'api/access/gates/{gate}/entrypoints',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.entrypoint': {
      uri: 'api/access/gates/{gate}/entrypoints/{entrypoint}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'entrypoint'],
      bindings: { gate: 'id', entrypoint: 'id' },
    },
    'api.web.access.gate.entrypoint.update': {
      uri: 'api/access/gates/{gate}/entrypoints/{entrypoint}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'entrypoint'],
      bindings: { gate: 'id', entrypoint: 'id' },
    },
    'api.web.access.gate.links': {
      uri: 'api/access/gates/{gate}/links',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.links.create': {
      uri: 'api/access/gates/{gate}/links',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.link.update': {
      uri: 'api/access/gates/{gate}/links/{link}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'link'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.link.delete': {
      uri: 'api/access/gates/{gate}/links/{link}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'link'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.cards': {
      uri: 'api/access/gates/{gate}/cards',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.card': {
      uri: 'api/access/gates/{gate}/cards/{card}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'card'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.card.search': {
      uri: 'api/access/gates/{gate}/cards/{card}/search',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'card'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.card.match': {
      uri: 'api/access/gates/{gate}/cards/{card}/match',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'card'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.user.enroll': {
      uri: 'api/access/gates/{gate}/user/enroll',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.user.status': {
      uri: 'api/access/gates/{gate}/user/{user}/status',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'user'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.user.internal': {
      uri: 'api/access/gates/{gate}/user/{user}/internal/{internal}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'user', 'internal'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.user.company': {
      uri: 'api/access/gates/{gate}/user/{user}/company',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'user'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.user.responsible': {
      uri: 'api/access/gates/{gate}/user/{user}/responsible/{responsible}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'user', 'responsible'],
      bindings: { gate: 'id', responsible: 'id' },
    },
    'api.web.access.gate.user.responsible.unset': {
      uri: 'api/access/gates/{gate}/user/{user}/responsible',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'user'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.user.card': {
      uri: 'api/access/gates/{gate}/user/{user}/card/{card}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'user', 'card'],
    },
    'api.web.access.gate.responsibles': {
      uri: 'api/access/gates/{gate}/responsibles',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.responsible.add': {
      uri: 'api/access/gates/{gate}/responsibles',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.responsible.update': {
      uri: 'api/access/gates/{gate}/responsibles/{company}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'company'],
      bindings: { gate: 'id', company: 'id' },
    },
    'api.web.access.gate.responsible.remove': {
      uri: 'api/access/gates/{gate}/responsibles/{company}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'company'],
      bindings: { gate: 'id', company: 'id' },
    },
    'api.web.access.gate.requisitions': {
      uri: 'api/access/gates/{gate}/requisitions',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.requisition.create': {
      uri: 'api/access/gates/{gate}/requisitions',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.requisition': {
      uri: 'api/access/gates/{gate}/requisitions/{requisition}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'requisition'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.requisition.review': {
      uri: 'api/access/gates/{gate}/requisitions/{requisition}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'requisition'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.requisition.delete': {
      uri: 'api/access/gates/{gate}/requisitions/{requisition}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'requisition'],
    },
    'api.web.access.gate.search.users': {
      uri: 'api/access/gates/{gate}/search/users',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate'],
      bindings: { gate: 'id' },
    },
    'api.web.access.gate.companies.update': {
      uri: 'api/access/gates/{gate}/companies/{company}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'gate', 'company'],
      bindings: { gate: 'id', company: 'id' },
    },
    'api.web.access.zone': {
      uri: 'api/access/zones/{zone}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.update': {
      uri: 'api/access/zones/{zone}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.create': {
      uri: 'api/access/zones/{zone}/create',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.users': {
      uri: 'api/access/zones/{zone}/users',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user': {
      uri: 'api/access/zones/{zone}/users/{user}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.requisitions': {
      uri: 'api/access/zones/{zone}/users/{user}/requisitions',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.patch': {
      uri: 'api/access/zones/{zone}/users/{user}',
      methods: ['PATCH'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.sync': {
      uri: 'api/access/zones/{zone}/users/{user}/sync',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.unregister': {
      uri: 'api/access/zones/{zone}/users/{user}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.unrequisition': {
      uri: 'api/access/zones/{zone}/users/{user}/requisition/{requisition}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user', 'requisition'],
      bindings: { zone: 'id', requisition: 'id' },
    },
    'api.web.access.zone.user.course': {
      uri: 'api/access/zones/{zone}/users/{user}/courses/{course}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user', 'course'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.course.restart': {
      uri: 'api/access/zones/{zone}/users/{user}/courses/{course}/restart',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user', 'course'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.course.cancel': {
      uri: 'api/access/zones/{zone}/users/{user}/courses/{course}/cancel',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user', 'course'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.course.document': {
      uri: 'api/access/zones/{zone}/users/{user}/courses/{course}/document',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user', 'course'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.course.setdate': {
      uri: 'api/access/zones/{zone}/users/{user}/courses/{course}/setdate',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user', 'course'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.course.approve': {
      uri: 'api/access/zones/{zone}/users/{user}/courses/{course}/approve',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user', 'course'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.user.course.reject': {
      uri: 'api/access/zones/{zone}/users/{user}/courses/{course}/reject',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user', 'course'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.search.admins': {
      uri: 'api/access/zones/{zone}/search/admins',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.search.companies': {
      uri: 'api/access/zones/{zone}/search/companies',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.search.users': {
      uri: 'api/access/zones/{zone}/search/users',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.requirements': {
      uri: 'api/access/zones/{zone}/requirements',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.requirements.course.add': {
      uri: 'api/access/zones/{zone}/requirements/{course}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'course'],
      bindings: { zone: 'id', course: 'id' },
    },
    'api.web.access.zone.requirements.course.module.add': {
      uri: 'api/access/zones/{zone}/requirements/{course}/{object}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'course', 'object'],
      bindings: { zone: 'id', course: 'id', object: 'id' },
    },
    'api.web.access.zone.requirements.course.remove': {
      uri: 'api/access/zones/{zone}/requirements/{course}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'course'],
      bindings: { zone: 'id', course: 'id' },
    },
    'api.web.access.zone.requirements.course.module.remove': {
      uri: 'api/access/zones/{zone}/requirements/{course}/{object}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'course', 'object'],
      bindings: { zone: 'id', course: 'id', object: 'id' },
    },
    'api.web.access.zone.certificates': {
      uri: 'api/access/zones/{zone}/certificates',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.certificate.add': {
      uri: 'api/access/zones/{zone}/certificates',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.certificate.remove': {
      uri: 'api/access/zones/{zone}/certificates/{certificateType}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'certificateType'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.admins': {
      uri: 'api/access/zones/{zone}/admins',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.admin.add': {
      uri: 'api/access/zones/{zone}/admins',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.admin.update': {
      uri: 'api/access/zones/{zone}/admins/{user}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user'],
      bindings: { zone: 'id', user: 'id' },
    },
    'api.web.access.zone.admin.remove': {
      uri: 'api/access/zones/{zone}/admins/{user}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone', 'user'],
      bindings: { zone: 'id', user: 'id' },
    },
    'api.web.access.zone.entrypoints': {
      uri: 'api/access/zones/{zone}/entrypoints',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.access.zone.entrypoints.set': {
      uri: 'api/access/zones/{zone}/entrypoints',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'zone'],
      bindings: { zone: 'id' },
    },
    'api.web.attempt': {
      uri: 'api/attempt/{attempt}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt'],
    },
    'api.web.attempt.language.update': {
      uri: 'api/attempt/{attempt}/language',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt'],
    },
    'api.web.attempt.conditions.update': {
      uri: 'api/attempt/{attempt}/conditions',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt'],
    },
    'api.web.attempt.auth.reject': {
      uri: 'api/attempt/{attempt}/auth/reject',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt'],
    },
    'api.web.attempt.auth.confirm': {
      uri: 'api/attempt/{attempt}/auth/confirm',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt'],
    },
    'api.web.attempt.node': {
      uri: 'api/attempt/{attempt}/nodes/{node}',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node'],
    },
    'api.web.attempt.node.course.start': {
      uri: 'api/attempt/{attempt}/nodes/{node}/course/start',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node'],
    },
    'api.web.attempt.node.session.enroll': {
      uri: 'api/attempt/{attempt}/nodes/{node}/session/{session}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node', 'session'],
      bindings: { session: 'id' },
    },
    'api.web.attempt.node.session.unenroll': {
      uri: 'api/attempt/{attempt}/nodes/{node}/session/{session}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node', 'session'],
      bindings: { session: 'id' },
    },
    'api.web.attempt.node.file.download': {
      uri: 'api/attempt/{attempt}/nodes/{node}/file/download',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node'],
    },
    'api.web.attempt.node.document': {
      uri: 'api/attempt/{attempt}/nodes/{node}/document',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node'],
    },
    'api.web.attempt.node.approve': {
      uri: 'api/attempt/{attempt}/nodes/{node}/approve',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node'],
    },
    'api.web.attempt.node.link': {
      uri: 'api/attempt/{attempt}/nodes/{node}/link',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node'],
    },
    'api.web.attempt.node.video.progress': {
      uri: 'api/attempt/{attempt}/nodes/{node}/video/progress',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node'],
    },
    'api.web.attempt.node.scorm.initalize': {
      uri: 'api/attempt/{attempt}/nodes/{node}/scorm/{item}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node', 'item'],
    },
    'api.web.attempt.node.scorm.commit': {
      uri: 'api/attempt/{attempt}/nodes/{node}/scorm/{item}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt', 'node', 'item'],
    },
    'api.web.company.dashboard.users': {
      uri: 'api/company/{company}/dashboard/users',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
      bindings: { company: 'id' },
    },
    'api.web.company.dashboard.completions': {
      uri: 'api/company/{company}/dashboard/completions/{days?}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company', 'days'],
      bindings: { company: 'id' },
    },
    'api.web.admin.check.email': {
      uri: 'api/check/{email}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'email'],
    },
    'api.web.admin.link.info': {
      uri: 'api/link/info',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.duration.seconds': {
      uri: 'api/duration/seconds/{seconds}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'seconds'],
    },
    'api.web.admin.approvals': {
      uri: 'api/approvals',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.approval': {
      uri: 'api/approvals/{approval}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'approval'],
    },
    'api.web.admin.approval.review': {
      uri: 'api/approvals/{approval}/review',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'approval'],
      bindings: { approval: 'id' },
    },
    'api.web.admin.approval.document': {
      uri: 'api/approvals/{approval}/document',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'approval'],
      bindings: { approval: 'id' },
    },
    'api.web.admin.approval.validity': {
      uri: 'api/approvals/{approval}/validity',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'approval'],
      bindings: { approval: 'id' },
    },
    'api.web.admin.companies': {
      uri: 'api/company/companies',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.company.update': {
      uri: 'api/company/companies/{company}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
      bindings: { company: 'id' },
    },
    'api.web.admin.company.courses': {
      uri: 'api/company/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.company.course': {
      uri: 'api/company/courses/{companyCourse}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.users': {
      uri: 'api/company/courses/{companyCourse}/users',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.enrollments.enroll': {
      uri: 'api/company/courses/{companyCourse}/enrollments',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.enrollment': {
      uri: 'api/company/courses/{companyCourse}/enrollments/{user}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'user'],
      bindings: { companyCourse: 'id', user: 'id' },
    },
    'api.web.admin.company.course.enrollment.unenroll': {
      uri: 'api/company/courses/{companyCourse}/enrollments/{user}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'user'],
      bindings: { companyCourse: 'id', user: 'id' },
    },
    'api.web.admin.company.course.sessions': {
      uri: 'api/company/courses/{companyCourse}/sessions',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.session.create': {
      uri: 'api/company/courses/{companyCourse}/sessions',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.session': {
      uri: 'api/company/courses/{companyCourse}/sessions/{session}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'session'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.session.update': {
      uri: 'api/company/courses/{companyCourse}/sessions/{session}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'session'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.session.open': {
      uri: 'api/company/courses/{companyCourse}/sessions/{session}/open',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'session'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.session.close': {
      uri: 'api/company/courses/{companyCourse}/sessions/{session}/close',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'session'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.session.toggle': {
      uri: 'api/company/courses/{companyCourse}/sessions/{session}/toggle',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'session'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.session.move': {
      uri: 'api/company/courses/{companyCourse}/sessions/{session}/move',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'session'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.session.participants': {
      uri: 'api/company/courses/{companyCourse}/sessions/{session}/participants',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'session'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.session.users.update': {
      uri: 'api/company/courses/{companyCourse}/sessions/{session}/users',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'session'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.session.users.unenroll': {
      uri: 'api/company/courses/{companyCourse}/sessions/{session}/users',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'session'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.sessions.requests': {
      uri: 'api/company/courses/{companyCourse}/session-requests',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse'],
      bindings: { companyCourse: 'id' },
    },
    'api.web.admin.company.course.sessions.requests.delete': {
      uri: 'api/company/courses/{companyCourse}/session-requests/{sessionRequest}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'companyCourse', 'sessionRequest'],
      bindings: { companyCourse: 'id', sessionRequest: 'id' },
    },
    'api.web.admin.courses': {
      uri: 'api/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.courses.create': {
      uri: 'api/courses',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.course.show': {
      uri: 'api/courses/{course}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
      bindings: { course: 'id' },
    },
    'api.web.admin.course.update': {
      uri: 'api/courses/{course}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
      bindings: { course: 'id' },
    },
    'api.web.admin.course.image.update': {
      uri: 'api/courses/{course}/image',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
      bindings: { course: 'id' },
    },
    'api.web.admin.course.revert': {
      uri: 'api/courses/{course}/revert',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
      bindings: { course: 'id' },
    },
    'api.web.admin.course.publish': {
      uri: 'api/courses/{course}/publish',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
      bindings: { course: 'id' },
    },
    'api.web.admin.roles': {
      uri: 'api/roles',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.role.create': {
      uri: 'api/roles',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.role': {
      uri: 'api/roles/{role}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'role'],
    },
    'api.web.admin.role.update': {
      uri: 'api/roles/{role}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'role'],
    },
    'api.web.admin.role.delete': {
      uri: 'api/roles/{role}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'role'],
    },
    'api.web.admin.role.users': {
      uri: 'api/roles/{role}/users',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'role'],
    },
    'api.web.admin.role.users.search': {
      uri: 'api/roles/{role}/users/search',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'role'],
    },
    'api.web.admin.role.users.add': {
      uri: 'api/roles/{role}/users',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'role'],
    },
    'api.web.admin.role.users.remove': {
      uri: 'api/roles/{role}/users',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'role'],
    },
    'api.web.admin.role.courses': {
      uri: 'api/roles/{role}/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'role'],
    },
    'api.web.admin.role.courses.add': {
      uri: 'api/roles/{role}/courses',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'role'],
    },
    'api.web.admin.role.courses.remove': {
      uri: 'api/roles/{role}/courses',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'role'],
    },
    'api.web.admin.users': {
      uri: 'api/users',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.user.impersonate': {
      uri: 'api/users/{user}/impersonate',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.certificate': {
      uri: 'api/users/{user}/certificate',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.relationships': {
      uri: 'api/users/{user}/relationships',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.relationships.maintain': {
      uri: 'api/users/{user}/relationships/maintain',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.relationship.update': {
      uri: 'api/users/{user}/relationships/{relationship}/update',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'relationship'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.relationship.employ': {
      uri: 'api/users/{user}/relationships/{relationship}/employ',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'relationship'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.relationship.remove': {
      uri: 'api/users/{user}/relationships/{relationship}/remove',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'relationship'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.relationship.restore': {
      uri: 'api/users/{user}/relationships/{relationship}/restore',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'relationship'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.courses': {
      uri: 'api/users/{user}/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.courses.enrollable': {
      uri: 'api/users/{user}/courses/enrollable',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.course': {
      uri: 'api/users/{user}/courses/{course}/{context?}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'course', 'context'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.course.restart': {
      uri: 'api/users/{user}/courses/{course}/restart',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'course'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.course.cancel': {
      uri: 'api/users/{user}/courses/{course}/cancel',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'course'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.course.document': {
      uri: 'api/users/{user}/courses/{course}/document',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'course'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.course.setdate': {
      uri: 'api/users/{user}/courses/{course}/setdate',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'course'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.course.approve': {
      uri: 'api/users/{user}/courses/{course}/approve',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'course'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.course.reject': {
      uri: 'api/users/{user}/courses/{course}/reject',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'course'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.course.enroll': {
      uri: 'api/users/{user}/courses/{course}/{company_course}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'course', 'company_course'],
      bindings: { user: 'id' },
    },
    'api.web.admin.user.course.unenroll': {
      uri: 'api/users/{user}/courses/{course}/{company_course}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'user', 'course', 'company_course'],
      bindings: { user: 'id' },
    },
    'api.web.admin.locations': {
      uri: 'api/locations',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.locations.create': {
      uri: 'api/locations',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.locations.map': {
      uri: 'api/locations/map/{size}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'size'],
    },
    'api.web.admin.locations.photo': {
      uri: 'api/locations/photo/{reference}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'reference'],
    },
    'api.web.admin.locations.search': {
      uri: 'api/locations/search',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.location.update': {
      uri: 'api/locations/{location}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'location'],
    },
    'api.web.admin.department.root': {
      uri: 'api/departments',
      methods: ['PATCH'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.admin.department': {
      uri: 'api/departments/{department}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'department'],
    },
    'api.web.admin.department.update': {
      uri: 'api/departments/{department}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'department'],
    },
    'api.web.admin.department.create': {
      uri: 'api/departments/{department}/create',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'department'],
    },
    'api.web.admin.department.add': {
      uri: 'api/departments/{department}/add',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'department'],
    },
    'api.web.admin.department.move': {
      uri: 'api/departments/{department}/move',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'department'],
    },
    'api.web.admin.department.detach': {
      uri: 'api/departments/{department}/detach',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'department'],
    },
    'api.web.salesorders.create': {
      uri: 'api/orders',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.salesorder': {
      uri: 'api/orders/{order}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'order'],
      bindings: { order: 'id' },
    },
    'api.web.salesorder.update': {
      uri: 'api/orders/{order}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'order'],
      bindings: { order: 'id' },
    },
    'api.web.salesorder.approve': {
      uri: 'api/orders/{order}/approve',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'order'],
      bindings: { order: 'id' },
    },
    'api.web.salesorder.bill': {
      uri: 'api/orders/{order}/bill',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'order'],
      bindings: { order: 'id' },
    },
    'api.web.salesorder.ledger': {
      uri: 'api/orders/{order}/ledger',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'order'],
      bindings: { order: 'id' },
    },
    'api.web.oauth.scopes': {
      uri: 'api/oauth/scopes',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.oauth.apps': {
      uri: 'api/oauth/authorized-applications',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.oauth.app.delete': {
      uri: 'api/oauth/authorized-applications/{token}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'token'],
    },
    'api.web.oauth.pats': {
      uri: 'api/oauth/personal-access-tokens',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.oauth.pat.create': {
      uri: 'api/oauth/personal-access-tokens',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.oauth.pat.delete': {
      uri: 'api/oauth/personal-access-tokens/{token}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'token'],
    },
    'api.web.oauth.clients': {
      uri: 'api/oauth/clients',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.oauth.client.create': {
      uri: 'api/oauth/clients',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.oauth.client.update': {
      uri: 'api/oauth/clients/{client}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'client'],
    },
    'api.web.oauth.client.delete': {
      uri: 'api/oauth/clients/{client}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'client'],
    },
    'api.web.oauth.client.tokens': {
      uri: 'api/oauth/clients/{client}/tokens',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'client'],
    },
    'api.web.oauth.client.token.create': {
      uri: 'api/oauth/clients/{client}/tokens',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'client'],
    },
    'api.web.oauth.client.token.delete': {
      uri: 'api/oauth/clients/{client}/tokens/{token}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'client', 'token'],
    },
    'api.web.oauth.client.webhooks': {
      uri: 'api/oauth/clients/{client}/webhooks',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'client'],
    },
    'api.web.oauth.client.webhook.set': {
      uri: 'api/oauth/clients/{client}/webhooks/{name}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'client', 'name'],
    },
    'api.web.oauth.client.webhook.test': {
      uri: 'api/oauth/clients/{client}/webhooks/{name}/test',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'client', 'name'],
    },
    'api.web.oauth.client.webhook.remove': {
      uri: 'api/oauth/clients/{client}/webhooks/{name}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'client', 'name'],
    },
    'api.web.munio.accounts': {
      uri: 'api/munio/accounts',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.munio.accounts.create': {
      uri: 'api/munio/accounts',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.munio.account.show': {
      uri: 'api/munio/accounts/{id}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id'],
    },
    'api.web.munio.account.update': {
      uri: 'api/munio/accounts/{id}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id'],
    },
    'api.web.munio.account.delete': {
      uri: 'api/munio/accounts/{id}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id'],
    },
    'api.web.munio.account.update.appearance': {
      uri: 'api/munio/accounts/{id}/appearance',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id'],
    },
    'api.web.munio.account.gates': {
      uri: 'api/munio/accounts/{id}/gates',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id'],
    },
    'api.web.munio.account.gate.create': {
      uri: 'api/munio/accounts/{id}/gates',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id'],
    },
    'api.web.munio.account.gate.delete': {
      uri: 'api/munio/accounts/{id}/gates/{gate}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id', 'gate'],
    },
    'api.web.munio.account.users': {
      uri: 'api/munio/accounts/{id}/users',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id'],
    },
    'api.web.munio.account.users.create': {
      uri: 'api/munio/accounts/{id}/users',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id'],
    },
    'api.web.munio.account.users.delete': {
      uri: 'api/munio/accounts/{id}/users/{user}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id', 'user'],
      bindings: { user: 'id' },
    },
    'api.web.munio.admins': {
      uri: 'api/munio/admins',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.munio.companies': {
      uri: 'api/munio/companies',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.munio.company': {
      uri: 'api/munio/companies/{company}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
      bindings: { company: 'id' },
    },
    'api.web.munio.company.update': {
      uri: 'api/munio/companies/{company}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
      bindings: { company: 'id' },
    },
    'api.web.munio.company.courses.addable': {
      uri: 'api/munio/companies/{company}/courses-addable',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
      bindings: { company: 'id' },
    },
    'api.web.munio.company.courses': {
      uri: 'api/munio/companies/{company}/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
      bindings: { company: 'id' },
    },
    'api.web.munio.company.course.add': {
      uri: 'api/munio/companies/{company}/courses',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
      bindings: { company: 'id' },
    },
    'api.web.munio.company.course.delete': {
      uri: 'api/munio/companies/{company}/courses/{course}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company', 'course'],
      bindings: { company: 'id', course: 'id' },
    },
    'api.web.munio.company.course.restore': {
      uri: 'api/munio/companies/{company}/courses/{course}/restore',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company', 'course'],
      bindings: { company: 'id', course: 'id' },
    },
    'api.web.munio.company.course.licenses': {
      uri: 'api/munio/companies/{company}/courses/{course}/licenses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company', 'course'],
      bindings: { company: 'id', course: 'id' },
    },
    'api.web.munio.company.course.licenses.update': {
      uri: 'api/munio/companies/{company}/courses/{course}/licenses',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company', 'course'],
      bindings: { company: 'id', course: 'id' },
    },
    'api.web.munio.certificate.import': {
      uri: 'api/munio/certificate/import/{type}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'type'],
    },
    'api.web.munio.charts.sales': {
      uri: 'api/munio/charts/sales',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.munio.charts.storage': {
      uri: 'api/munio/charts/storage',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.munio.courses': {
      uri: 'api/munio/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'api.web.munio.course.update': {
      uri: 'api/munio/courses/{course}',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'course'],
      bindings: { course: 'id' },
    },
    'lms.root': { uri: '/', methods: ['GET', 'HEAD'], domain: '{account}.{domain}', parameters: ['account', 'domain'] },
    'lms.play': {
      uri: 'play',
      methods: ['GET', 'POST', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'lms.play.error': {
      uri: 'play/error',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'lms.play.session': {
      uri: 'play/session/{session}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'session'],
    },
    'lms.play.attempt': {
      uri: 'play/attempt/{attempt}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'attempt'],
    },
    'lms.index': {
      uri: '{language}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.language': {
      uri: '{language}/language',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.login': {
      uri: '{language}/login',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.login.recover': {
      uri: '{language}/login/recover',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.login.reset': {
      uri: '{language}/login/reset/{token}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'token'],
    },
    'lms.logout': {
      uri: '{language}/logout',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.registration': {
      uri: '{language}/registration',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.registration.invite': {
      uri: '{language}/registration/{invite}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite'],
    },
    'lms.invite.accept': {
      uri: '{language}/invite/accept',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.invite.user': {
      uri: '{language}/invite/user/{invite}/{all?}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite', 'all'],
      bindings: { invite: 'uuid' },
    },
    'lms.invite.user.accept': {
      uri: '{language}/invite/user/{invite}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite'],
    },
    'lms.invite.user.decline': {
      uri: '{language}/invite/user/{invite}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite'],
    },
    'lms.access': {
      uri: '{language}/access',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.access.gate': {
      uri: '{language}/access/gate/{gate}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'gate'],
      bindings: { gate: 'id' },
    },
    'lms.access.gate.requisitions': {
      uri: '{language}/access/gate/{gate}/requisitions',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'gate'],
      bindings: { gate: 'id' },
    },
    'lms.access.gate.links': {
      uri: '{language}/access/gate/{gate}/links',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'gate'],
      bindings: { gate: 'id' },
    },
    'lms.access.gate.cards': {
      uri: '{language}/access/gate/{gate}/cards',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'gate'],
      bindings: { gate: 'id' },
    },
    'lms.access.gate.zones': {
      uri: '{language}/access/gate/{gate}/zones',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'gate'],
      bindings: { gate: 'id' },
    },
    'lms.access.gate.companies': {
      uri: '{language}/access/gate/{gate}/companies',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'gate'],
      bindings: { gate: 'id' },
    },
    'lms.access.gate.settings': {
      uri: '{language}/access/gate/{gate}/settings',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'gate'],
      bindings: { gate: 'id' },
    },
    'lms.shop.redirect': {
      uri: '{language}/shop',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
      bindings: { account: 'subdomain' },
    },
    'lms.shop.redirect.old': {
      uri: '{language}/shop/{oldshopsections}/{all?}',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'oldshopsections', 'all'],
      bindings: { account: 'subdomain' },
    },
    'lms.shop': {
      uri: '{language}/shop/{shop}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
      bindings: { shop: 'slug' },
    },
    'lms.shop.cart': {
      uri: '{language}/shop/{shop}/cart',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.terms': {
      uri: '{language}/shop/{shop}/terms',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.product': {
      uri: '{language}/shop/{shop}/product/{product}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'product'],
      bindings: { shop: 'slug' },
    },
    'lms.shop.product.modal': {
      uri: '{language}/shop/{shop}/product/{product}/modal',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'product'],
    },
    'lms.shop.product.demo': {
      uri: '{language}/shop/{shop}/product/{product}/demo',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'product'],
    },
    'lms.shop.product.session_request.unsubscribe': {
      uri: '{language}/shop/{shop}/product/{product}/session-request/{session_request}/unsubscribe',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'product', 'session_request'],
    },
    'lms.shop.checkout': {
      uri: '{language}/shop/{shop}/checkout',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.checkout.complete': {
      uri: '{language}/shop/{shop}/checkout/complete',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.checkout.exit': {
      uri: '{language}/shop/{shop}/checkout/complete',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.checkout.resume': {
      uri: '{language}/shop/{shop}/checkout/resume/{cart}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'cart'],
      bindings: { cart: 'uuid' },
    },
    'lms.shop.admin.members': {
      uri: '{language}/shop/{shop}/admin/members',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.members.form': {
      uri: '{language}/shop/{shop}/admin/members/create',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.members.create': {
      uri: '{language}/shop/{shop}/admin/members/create',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.members.remove': {
      uri: '{language}/shop/{shop}/admin/members/remove',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.suppliers': {
      uri: '{language}/shop/{shop}/admin/suppliers',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.suppliers.form': {
      uri: '{language}/shop/{shop}/admin/suppliers/create',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.suppliers.create': {
      uri: '{language}/shop/{shop}/admin/suppliers/create',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.suppliers.remove': {
      uri: '{language}/shop/{shop}/admin/suppliers/remove',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.orders': {
      uri: '{language}/shop/{shop}/admin/orders',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.order': {
      uri: '{language}/shop/{shop}/admin/orders/{order}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'order'],
      bindings: { order: 'id' },
    },
    'lms.shop.admin.order.invoice': {
      uri: '{language}/shop/{shop}/admin/orders/{order}/invoices/{invoice}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'order', 'invoice'],
    },
    'lms.shop.admin.products': {
      uri: '{language}/shop/{shop}/admin/products',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.subscriptions': {
      uri: '{language}/shop/{shop}/admin/subscriptions',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.categories': {
      uri: '{language}/shop/{shop}/admin/categories',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.categories.form': {
      uri: '{language}/shop/{shop}/admin/categories/create',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.categories.create': {
      uri: '{language}/shop/{shop}/admin/categories/create',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.shop.admin.category': {
      uri: '{language}/shop/{shop}/admin/categories/{category}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'category'],
    },
    'lms.shop.admin.category.update': {
      uri: '{language}/shop/{shop}/admin/categories/{category}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'category'],
    },
    'lms.shop.admin.category.delete': {
      uri: '{language}/shop/{shop}/admin/categories/{category}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'category'],
    },
    'lms.shop.admin.category.products': {
      uri: '{language}/shop/{shop}/admin/categories/{category}/products',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'category'],
    },
    'lms.shop.admin.category.products.update': {
      uri: '{language}/shop/{shop}/admin/categories/{category}/products',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'category'],
    },
    'lms.shop.admin.category.reorder': {
      uri: '{language}/shop/{shop}/admin/categories/{category}/reorder',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop', 'category'],
    },
    'lms.shop.admin.settings': {
      uri: '{language}/shop/{shop}/admin/settings',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
      bindings: { account: 'subdomain' },
    },
    'lms.shop.admin.settings.update': {
      uri: '{language}/shop/{shop}/admin/settings/update',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
      bindings: { shop: 'slug' },
    },
    'lms.shop.admin.settings.update.message': {
      uri: '{language}/shop/{shop}/admin/settings/message',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'shop'],
    },
    'lms.user.course.attempt.resource.storyline.index': {
      uri: '{language}/user/course/{course}/attempt/{attempt}/resource/{resource}/story.html',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'course', 'attempt', 'resource'],
    },
    'lms.user.course.attempt.resource.storyline.index.html5': {
      uri: '{language}/user/course/{course}/attempt/{attempt}/resource/{resource}/story_html5.html',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'course', 'attempt', 'resource'],
    },
    'lms.user.course.attempt.resource.storyline.frame': {
      uri: '{language}/user/course/{course}/attempt/{attempt}/resource/{resource}/story_content/frame.xml',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'course', 'attempt', 'resource'],
    },
    'lms.user.course.attempt.resource.storyline.frame.html5': {
      uri: '{language}/user/course/{course}/attempt/{attempt}/resource/{resource}/html5/data/js/frame.js',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'course', 'attempt', 'resource'],
    },
    'lms.user.course.attempt.resource.file': {
      uri: '{language}/user/course/{course}/attempt/{attempt}/resource/{resource}/{file?}',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'course', 'attempt', 'resource', 'file'],
    },
    'lms.resource.download': {
      uri: '{language}/resource/{resource}/{name}.{extension}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'resource', 'name', 'extension'],
      bindings: { resource: 'id' },
    },
    'lms.home': {
      uri: '{language}/home',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user': {
      uri: '{language}/user',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.settings.profile': {
      uri: '{language}/user/settings/profile',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.settings.account': {
      uri: '{language}/user/settings/account',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.settings.certificates': {
      uri: '{language}/user/settings/certificates',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.settings.profile.update': {
      uri: '{language}/user/settings/profile',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.settings.account.update': {
      uri: '{language}/user/settings/account',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.settings.confirm': {
      uri: '{language}/user/settings/confirm',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.access': {
      uri: '{language}/user/access',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.access.link': {
      uri: '{language}/user/access/link/{link}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'link'],
      bindings: { link: 'slug' },
    },
    'lms.user.access.link.register': {
      uri: '{language}/user/access/link/{link}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'link'],
      bindings: { link: 'slug' },
    },
    'lms.user.access.company': {
      uri: '{language}/user/access/company/{company}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'company'],
      bindings: { company: 'id' },
    },
    'lms.user.access.company.requirement': {
      uri: '{language}/user/access/company/{company}/requirement/{requirement}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'company', 'requirement'],
      bindings: { company: 'id', requirement: 'id' },
    },
    'lms.user.orders': {
      uri: '{language}/user/orders',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.order': {
      uri: '{language}/user/orders/{order}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'order'],
      bindings: { order: 'id' },
    },
    'lms.user.courses': {
      uri: '{language}/user/course',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.courses.invite': {
      uri: '{language}/user/course',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.course': {
      uri: '{language}/user/course/{course}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'course'],
    },
    'lms.user.course.attempt': {
      uri: '{language}/user/course/{course}/attempt/{attempt}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'course', 'attempt'],
    },
    'lms.user.course.attempt.completed': {
      uri: '{language}/user/course/{course}/attempt/{attempt}/completed',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'course', 'attempt'],
    },
    'lms.user.inbox': {
      uri: '{language}/user/inbox',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.user.inbox.message': {
      uri: '{language}/user/inbox/{message}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'message'],
    },
    'lms.user.inbox.attachment': {
      uri: '{language}/user/inbox/{message}/{attachment}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'message', 'attachment'],
    },
    'lms.admin': {
      uri: '{language}/admin',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.companies': {
      uri: '{language}/admin/companies',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.roles': {
      uri: '{language}/admin/roles',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.approvals': {
      uri: '{language}/admin/approvals',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.message.body': {
      uri: '{language}/admin/messages/{message}/body',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'message'],
      bindings: { message: 'id' },
    },
    'lms.admin.orders': {
      uri: '{language}/admin/orders',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.order': {
      uri: '{language}/admin/orders/{order}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'order'],
      bindings: { order: 'id' },
    },
    'lms.admin.subscriptions': {
      uri: '{language}/admin/subscriptions',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.courses': {
      uri: '{language}/admin/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.courses.create': {
      uri: '{language}/admin/courses/create',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.course': {
      uri: '{language}/admin/courses/{id}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.participants': {
      uri: '{language}/admin/courses/{id}/enrollment',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.enrollment': {
      uri: '{language}/admin/courses/{id}/enrollment',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.enrollment.toggle': {
      uri: '{language}/admin/courses/{id}/enrollment/toggle',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.sessions': {
      uri: '{language}/admin/courses/{id}/sessions',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.details': {
      uri: '{language}/admin/courses/{id}/details',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.settings': {
      uri: '{language}/admin/courses/{id}/settings',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.settings.update': {
      uri: '{language}/admin/courses/{id}/settings',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.licenses': {
      uri: '{language}/admin/courses/{id}/licenses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.session_requests': {
      uri: '{language}/admin/courses/{id}/session-requests',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.product': {
      uri: '{language}/admin/courses/{id}/product',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.product.update': {
      uri: '{language}/admin/courses/{id}/product',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.product.create': {
      uri: '{language}/admin/courses/{id}/product/create',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.product.store': {
      uri: '{language}/admin/courses/{id}/product/create',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.product.update.translations': {
      uri: '{language}/admin/courses/{id}/product/translations',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.markets': {
      uri: '{language}/admin/courses/{id}/markets',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.admin.course.market': {
      uri: '{language}/admin/courses/{id}/markets/{shop}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id', 'shop'],
      bindings: { shop: 'slug' },
    },
    'lms.admin.course.market.submit': {
      uri: '{language}/admin/courses/{id}/markets/{shop}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id', 'shop'],
      bindings: { shop: 'slug' },
    },
    'lms.admin.course.market.withdraw': {
      uri: '{language}/admin/courses/{id}/markets/{shop}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id', 'shop'],
      bindings: { shop: 'slug' },
    },
    'lms.admin.users': {
      uri: '{language}/admin/users',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.users.admins': {
      uri: '{language}/admin/users/admins',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.users.locks': {
      uri: '{language}/admin/users/locks',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.users.locks.remove': {
      uri: '{language}/admin/users/locks',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.user': {
      uri: '{language}/admin/users/{user}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'user'],
    },
    'lms.admin.user.update': {
      uri: '{language}/admin/users/{user}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'user'],
    },
    'lms.admin.user.password': {
      uri: '{language}/admin/users/{user}/password',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'user'],
    },
    'lms.admin.user.relationships': {
      uri: '{language}/admin/users/{user}/relationships',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'user'],
    },
    'lms.admin.user.courses': {
      uri: '{language}/admin/users/{user}/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'user'],
    },
    'lms.admin.user.courses.update': {
      uri: '{language}/admin/users/{user}/courses',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'user'],
    },
    'lms.admin.user.roles': {
      uri: '{language}/admin/users/{user}/roles',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'user'],
    },
    'lms.admin.user.roles.update': {
      uri: '{language}/admin/users/{user}/roles',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'user'],
    },
    'lms.admin.user.permissions': {
      uri: '{language}/admin/users/{user}/permissions',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'user'],
    },
    'lms.admin.user.permissions.update': {
      uri: '{language}/admin/users/{user}/permissions',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'user'],
    },
    'lms.admin.enrollment': {
      uri: '{language}/admin/enrollment',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.enrollment.users': {
      uri: '{language}/admin/enrollment/users',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.enrollment.create': {
      uri: '{language}/admin/enrollment/create',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.enrollment.courses': {
      uri: '{language}/admin/enrollment/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.enrollment.code': {
      uri: '{language}/admin/enrollment/code',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.enrollment.recipients': {
      uri: '{language}/admin/enrollment/invite/{invite}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite'],
    },
    'lms.admin.enrollment.recipients.add': {
      uri: '{language}/admin/enrollment/invite/{invite}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite'],
    },
    'lms.admin.enrollment.settings': {
      uri: '{language}/admin/enrollment/invite/{invite}/settings',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite'],
    },
    'lms.admin.enrollment.settings.update': {
      uri: '{language}/admin/enrollment/invite/{invite}/settings',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite'],
    },
    'lms.admin.enrollment.delete': {
      uri: '{language}/admin/enrollment/invite/{invite}/delete',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite'],
    },
    'lms.admin.enrollment.archive': {
      uri: '{language}/admin/enrollment/invite/{invite}/archive',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite'],
    },
    'lms.admin.enrollment.restore': {
      uri: '{language}/admin/enrollment/invite/{invite}/restore',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invite'],
    },
    'lms.admin.reports': {
      uri: '{language}/admin/reports',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.reports.licenses': {
      uri: '{language}/admin/reports/licenses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.reports.diplomas': {
      uri: '{language}/admin/reports/diplomas',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.reports.status': {
      uri: '{language}/admin/reports/status',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.reports.reminders': {
      uri: '{language}/admin/reports/reminders',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.reports.reminders.expiration': {
      uri: '{language}/admin/reports/reminders/expiration',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.reports.reminders.activity': {
      uri: '{language}/admin/reports/reminders/activity',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.reports.reminders.message': {
      uri: '{language}/admin/reports/reminders/message/{message}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'message'],
    },
    'lms.admin.account': {
      uri: '{language}/admin/account',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.account.information': {
      uri: '{language}/admin/account/information',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.account.update.info': {
      uri: '{language}/admin/account/information',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.account.update.layout': {
      uri: '{language}/admin/account/information/layout',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.account.departments': {
      uri: '{language}/admin/account/departments',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.account.locations': {
      uri: '{language}/admin/account/locations',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.account.api': {
      uri: '{language}/admin/account/api',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.admin.account.api.client': {
      uri: '{language}/admin/account/api/{client}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'client'],
    },
    'lms.admin.resource.upload': {
      uri: '{language}/admin/resources/{object}/{resource}/{token}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'object', 'resource', 'token'],
      bindings: { object: 'id', resource: 'ulid' },
    },
    'lms.munio': {
      uri: '{language}/munio',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.munio.audits': {
      uri: '{language}/munio/audits',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.munio.companies': {
      uri: '{language}/munio/companies',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.munio.company.details': {
      uri: '{language}/munio/companies/{company}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'company'],
      bindings: { company: 'id' },
    },
    'lms.munio.company.courses': {
      uri: '{language}/munio/companies/{company}/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'company'],
      bindings: { company: 'id' },
    },
    'lms.munio.company.account': {
      uri: '{language}/munio/companies/{company}/account',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'company'],
      bindings: { company: 'id' },
    },
    'lms.munio.company.access': {
      uri: '{language}/munio/companies/{company}/access',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'company'],
      bindings: { company: 'id' },
    },
    'lms.munio.company.audit': {
      uri: '{language}/munio/companies/{company}/audit',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'company'],
      bindings: { company: 'id' },
    },
    'lms.munio.courses': {
      uri: '{language}/munio/courses',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.munio.course': {
      uri: '{language}/munio/courses/{course}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'course'],
      bindings: { course: 'id' },
    },
    'lms.munio.integrations': {
      uri: '{language}/munio/integrations',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.munio.integration': {
      uri: '{language}/munio/integrations/{integration}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'integration'],
      bindings: { integration: 'id' },
    },
    'lms.munio.certificates': {
      uri: '{language}/munio/certificates',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.munio.certificates.import': {
      uri: '{language}/munio/certificates/import',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.munio.competencies': {
      uri: '{language}/munio/competencies',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.munio.components': {
      uri: '{language}/munio/components',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.munio.tools': {
      uri: '{language}/munio/tools',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.course.free': {
      uri: '{language}/course/free/{name}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'name'],
    },
    'lms.pdf.diploma': {
      uri: '{language}/pdf/diploma/{id}.pdf',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.pdf.salesorder.confirmation': {
      uri: '{language}/pdf/salesorder/confirmation/{id}.pdf',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'id'],
    },
    'lms.pdf.salesorder.invoice': {
      uri: '{language}/pdf/salesorder/invoice/{invoice}.pdf',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'invoice'],
      bindings: { invoice: 'id' },
    },
    'lms.terms': {
      uri: '{language}/terms',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language'],
    },
    'lms.error': {
      uri: '{language}/error/{code}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'code'],
    },
    'lms.email.preview': {
      uri: '{language}/email/{type}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'language', 'type'],
    },
    'lms.context': {
      uri: 'context',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'lms.context.company': {
      uri: 'context/{company}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'company'],
    },
    'lms.pdf.diploma.redirect': {
      uri: 'pdf/diploma/{id}.pdf',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'id'],
    },
    'lms.document': {
      uri: 'document/{document}-{slug}/{mode?}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'document', 'slug', 'mode'],
    },
    keepalive: {
      uri: 'keepalive',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'lms.tutorial': {
      uri: 'tutorial/{identifier}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'identifier'],
    },
    'lms.shop.product.demostory': {
      uri: 'demo/{product}/story.html',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'product'],
      bindings: { product: 'id' },
    },
    xapi: {
      uri: 'xapi',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.agents': {
      uri: 'xapi/agents',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.agents.profile': {
      uri: 'xapi/agents/profile',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.activities': {
      uri: 'xapi/activities',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.activities.profile': {
      uri: 'xapi/activities/profile',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.activities.state': {
      uri: 'xapi/activities/state',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.activities.state.post': {
      uri: 'xapi/activities/state',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.activities.state.put': {
      uri: 'xapi/activities/state',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.statements': {
      uri: 'xapi/statements',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.statements.post': {
      uri: 'xapi/statements',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.statements.put': {
      uri: 'xapi/statements',
      methods: ['PUT'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    'xapi.about': {
      uri: 'xapi/about',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain'],
    },
    void: {
      uri: 'void/{all?}',
      methods: ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'all'],
    },
    'dynimage.display': {
      uri: '{cbhash}/img/{group}/{size}/{id}.{extension}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'cbhash', 'group', 'size', 'id', 'extension'],
    },
    'dynimage.source': {
      uri: '{cbhash}/img/{group}/{id}.{extension}',
      methods: ['GET', 'HEAD'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'cbhash', 'group', 'id', 'extension'],
    },
    'dynimage.update': {
      uri: 'img/{group}/{id}.{extension}',
      methods: ['POST'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'group', 'id', 'extension'],
    },
    'dynimage.remove': {
      uri: 'img/{group}/{id}.{extension}',
      methods: ['DELETE'],
      domain: '{account}.{domain}',
      parameters: ['account', 'domain', 'group', 'id', 'extension'],
    },
    'root.invite': { uri: 'r/{invite}', methods: ['GET', 'HEAD'], parameters: ['invite'] },
    'munioaccess.root': { uri: '/', methods: ['GET', 'HEAD'], domain: '{accessdomain}', parameters: ['accessdomain'] },
    'munioaccess.link': {
      uri: 'l/{link}',
      methods: ['GET', 'HEAD'],
      domain: '{accessdomain}',
      parameters: ['accessdomain', 'link'],
      bindings: { link: 'slug' },
    },
    root: { uri: '/', methods: ['GET', 'HEAD'] },
    health: { uri: 'health', methods: ['GET', 'HEAD'] },
    fallback: { uri: '{fallbackPlaceholder}', methods: ['GET', 'HEAD'], parameters: ['fallbackPlaceholder'] },
  },
}

if (typeof window !== 'undefined' && typeof window.Ziggy !== 'undefined') {
  Object.assign(Ziggy.routes, window.Ziggy.routes)
}

export { Ziggy }
