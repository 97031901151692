<template>
  <div class="product-price" :class="{ 'product-price--right': right }">
    <small v-if="memberPriceLabel" class="text-sm text-info text-wrap" :class="{ right: right }">
      {{ memberPriceLabel }}
    </small>

    <div class="flex flex-col">
      <span v-if="discountPrice" class="line-through opaque text-md">
        {{ discountPrice }}
      </span>
      <span class="text-xl">
        {{ mainPrice }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price: { type: Object },
    original: { type: Object },
    memberPrice: { type: Object },
    membership: { type: Boolean },
    right: { type: Boolean },
  },

  computed: {
    hasDiscount() {
      return this.price?.value && this.original?.value && this.price.value < this.original.value
    },

    mainPrice() {
      if (this.membership) {
        if (this.memberPrice?.value) {
          if (this.hasDiscount && this.price.value < this.memberPrice.value) {
            return !this.price.display ? trans('For free') : this.price.display
          }

          return !this.memberPrice.value ? trans('For free') : this.memberPrice.display
        }
      }

      if (this.hasDiscount) {
        return !this.price.display ? trans('For free') : this.price.display
      }

      return !this.original.value ? trans('For free') : this.original.display
    },
    discountPrice() {
      if (this.membership) {
        if (this.memberPrice?.value) {
          if (this.hasDiscount && this.memberPrice.value < this.price.value) {
            return null
          }

          if (this.hasDiscount && this.price.value < this.memberPrice.value) {
            return !this.original.display ? trans('For free') : this.original.display
          }
        }
      }

      if (this.hasDiscount) {
        return !this.original.display ? trans('For free') : this.original.display
      }

      return null
    },
    memberPriceLabel() {
      if (this.membership) {
        if (this.memberPrice?.value) {
          if (this.hasDiscount && this.price.value < this.memberPrice.value) {
            return null
          }

          return trans('Member price')
        }
      } else {
        if (this.memberPrice?.value) {
          if (!this.original && !this.price) {
            return trans('Members only')
          }

          if (this.hasDiscount) {
            return null
          }

          return `${this.memberPrice.display} (${trans('Member price')})`
        }
      }

      return null
    },
  },
}
</script>
