<template>
  <div>
    <table class="table table-sm mb-8">
      <tbody>
        <tr>
          <th width="30%">{{ trans('Name') }}</th>
          <td>{{ `${registration.firstname} ${registration.lastname}` }}</td>
        </tr>
        <tr>
          <th>{{ trans('E-mail') }}</th>
          <td>{{ registration.email }}</td>
        </tr>
        <tr>
          <th>{{ trans('Employer') }}</th>
          <td>{{ employer.name }}</td>
        </tr>
      </tbody>
    </table>

    <MdlSwitch v-model="registration.accepted">
      <span v-html="acceptLabel"></span>
    </MdlSwitch>
    <div class="mt-2"></div>
    <MdlSwitch v-model="registration.newsletter" :data-qa-id="pendo('receiveNewsletter')">
      {{ trans('Yes, I want to receive newsletters') }}
    </MdlSwitch>

    <Captcha class="mt-8" size="flexible" @verified="onCaptchaVerified" />
  </div>
</template>

<script>
import MdlSwitch from '@/munio/vue/components/mdl/Switch.vue'
import Captcha from '@component/Captcha.vue'

export default {
  inject: ['registration'],

  components: {
    Captcha,
    MdlSwitch,
  },

  data() {
    return {
      accepted: false,
    }
  },

  computed: {
    acceptLabel() {
      return trans('I accept the <a href=":link">terms & conditions</a>', {
        link: `/${Munio.config.i18n.key}/terms`,
      })
    },
    employer() {
      return this.$store.state.employer || this.registration.company
    },
  },

  methods: {
    onCaptchaVerified(token) {
      this.$store.commit('captcha', token)
    },

    validate() {
      return true
    },
  },
}
</script>
